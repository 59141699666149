import { useRef } from 'react';
import { ResponsiveImage } from '@dx-ui/osc-responsive-image';
import { useMediaQuery, useResizeObserver } from 'usehooks-ts';
import cx from 'classnames';
import { YouTubeVideo } from '@dx-ui/osc-youtube-video';
import {
  Video,
  VideoCaptions,
  VideoControls,
  VideoTranscript,
  useVideoPlayerClasses,
  useVideoPlayer,
  VideoPlayerMarkup,
} from '@dx-ui/osc-video-player';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import type { BrandHorizontalTabPanelProps } from './brand-horizontal-tabs.types';
import { useRect } from '@dx-ui/utilities-use-rect';
import { HeadingLevel } from '@dx-ui/osc-heading-level';

export const BrandHorizontalTabbedSectionContent = ({
  brandComponentTheme,
  copy,
  longDescription,
  headline,
  image,
  link,
  media,
}: BrandHorizontalTabPanelProps) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  const isYouTubeShort = media?.video?.source === 'youtube-short';
  const isPortrait = media?.multimedia?.orientation === 'portrait';
  const isLandscape = media?.multimedia?.orientation === 'landscape';
  const isMultimediaVideo = Boolean(media?.multimedia?.videoUrl);
  const isVideo = Boolean(media?.video?.url || isMultimediaVideo);
  const isVerticalVideo = isVideo && (isYouTubeShort || isPortrait);
  const isImage = Boolean(image?.alt && image?.url);
  const isCinemagraph = !!media?.multimedia?.altText;
  const isMuteVisible = !isCinemagraph && !media?.multimedia?.isMuted;
  const captionData = image?.captionData;
  const videoUrl = media?.multimedia?.videoUrl || '';
  const playerProps = useVideoPlayer({
    ...media?.multimedia,
    videoUrl,
    wrapperElement: ref,
  });
  const playerClassNames = useVideoPlayerClasses({ isLandscape });
  const hasActiveTranscript = Boolean(playerProps.videoTranscriptProps?.activeTranscript);
  const isLarge = useMediaQuery('(min-width: 1024px)');
  const videoSize = useResizeObserver({ ref: playerProps.videoProps.videoElement });
  const height = videoSize?.height ?? 0;
  const transcriptStyle = isLarge && !isVerticalVideo ? { height: `${height}px` } : undefined;
  const hasMediaContent = isImage || isVideo;

  const Transcript = (
    <VideoTranscript
      {...playerProps.videoTranscriptProps}
      style={transcriptStyle}
      brandComponentTheme={brandComponentTheme}
      className={cx('overflow-hidden !p-0 lg:mb-6', {
        '[&>ol]:lg:max-h-full [&>ol]:lg:pb-20': !isVerticalVideo,
        '[&>ol]:lg:max-h-64': isVerticalVideo,
      })}
    />
  );

  return (
    <div
      data-testid="tabbed-section-content-wrapper"
      ref={ref}
      className={cx('brand-wa:lg:px-0 relative flex lg:mx-8 lg:mt-8', {
        'flex-row-reverse': hasMediaContent,
        'justify-center': isVerticalVideo,
        'justify-between': !isVerticalVideo,
        'items-start': hasActiveTranscript,
      })}
    >
      {media?.multimedia?.markupSchemas ? (
        <VideoPlayerMarkup markupSchemas={media.multimedia.markupSchemas} />
      ) : null}
      <div
        className={cx('flex w-full flex-col justify-center', {
          'max-w-sm': isVerticalVideo,
          'lg:w-1/2': !isVerticalVideo && hasMediaContent,
        })}
      >
        <div
          className={cx('lg:ps-7', {
            'lg:pt-0 pt-10': !hasActiveTranscript,
          })}
        >
          <div className="hidden lg:block">{Transcript}</div>
          {headline ? (
            <HeadingLevel
              headingLevelFallback={3}
              className={cx('heading-2xl lg:heading-4xl mb-4 lg:mb-6', {
                'tabbed-section-content-header-text-dark': isDark,
                'tabbed-section-content-header-text-light': isLight,
              })}
              data-testid="brandTextHeader"
            >
              {headline}
            </HeadingLevel>
          ) : null}
          {copy ? (
            <BrandTextBody
              brandComponentTheme={brandComponentTheme}
              className={cx('mb-4 lg:mb-6', {
                'tabbed-section-content-body-text-dark': isDark,
                'tabbed-section-content-body-text-light': isLight,
                'tabbed-section-content-body-text': !isDark && !isLight,
              })}
            >
              {copy}
            </BrandTextBody>
          ) : null}
        </div>

        <div
          className={cx('start-0 pe-7 lg:absolute', {
            'top-0': hasActiveTranscript,
            'lg:w-4/12 lg:start-[calc(100%/3/2)]': isVerticalVideo,
            'lg:w-1/2': !isVerticalVideo && !isImage,
            'lg:min-w-[55%]': isImage,
          })}
        >
          {media?.video?.url && media?.video.title ? (
            <YouTubeVideo
              {...media.video}
              wrapperClassName="flex-1"
              isAutoPlay={false}
              isAutoLoop={false}
            />
          ) : videoUrl ? (
            <>
              <div className={cx(playerClassNames.wrapperClassNames, 'mb-8 lg:mb-0')}>
                <Video
                  {...playerProps.videoProps}
                  captionData={media?.multimedia?.captionData}
                  isAutoPlay={media?.multimedia?.isAutoPlay}
                  posterImageUrl={media?.multimedia?.posterImageUrl}
                  videoId={media?.multimedia?.videoId}
                  videoUrl={videoUrl}
                  role={isCinemagraph ? 'img' : undefined}
                  aria-label={isCinemagraph ? media?.multimedia?.altText : undefined}
                />
                <VideoCaptions
                  {...playerProps.videoCaptionProps}
                  brandComponentTheme={brandComponentTheme}
                  className={playerClassNames.captionClassNames}
                />
                <VideoControls
                  buttonOptions={{
                    mute: { isVisible: isMuteVisible },
                  }}
                  videoVariant={isCinemagraph ? 'cinemagraph' : undefined}
                  {...playerProps.videoControlsProps}
                  brandComponentTheme={brandComponentTheme}
                  className={playerClassNames.controlsClassNames}
                />
              </div>
              <div className="lg:hidden">{Transcript}</div>
            </>
          ) : null}

          {image?.alt && image?.url ? (
            <ResponsiveImage
              altText={image.alt}
              imageUrl={image.url}
              wrapperClassName="flex-[1.3]"
              aspectRatio="3:2"
              width={rect?.width ?? 0}
              captionData={captionData}
              className="image-corner-radius"
            />
          ) : null}
        </div>
        <div className={cx('mt-4 md:mb-2 lg:mt-0 lg:ps-7')}>
          {longDescription ? (
            <BrandTextBody
              className={cx('mb-4 font-sans lg:mb-6', {
                'tabbed-section-content-body-text-dark': isDark,
                'tabbed-section-content-body-text-light': isLight,
                'pt-8 sm:pt-4': isMultimediaVideo && !hasActiveTranscript,
              })}
              brandComponentTheme={brandComponentTheme}
            >
              {longDescription}
            </BrandTextBody>
          ) : null}

          {link && link.label && link.url ? (
            <BrandLink
              url={link.url}
              label={link.label}
              onClick={link.onClick}
              isNewWindow={link.isNewWindow}
              showNewWindowIcon={link.isNewWindow}
              brandComponentTheme={brandComponentTheme}
              data-conductrics-goal={link.experimentationConfiguration?.goal}
              data-conductrics-value={link.experimentationConfiguration?.value}
            />
          ) : null}
        </div>
      </div>
      {hasMediaContent ? (
        <div
          className={cx('hidden lg:block', {
            'aspect-[9/16] lg:w-4/12': isVerticalVideo,
            'aspect-[3/2] lg:w-1/2': !isVerticalVideo && !isImage,
            'aspect-[3/2] lg:min-w-[55%]': isImage,
          })}
        />
      ) : null}
    </div>
  );
};
