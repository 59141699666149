import { env } from '@dx-ui/framework-env';
import { type BrandCode, searchUriBuilder } from '@dx-ui/framework-uri-builder';
import type { FormData } from './brands-search';
import { getVisitorId } from '@dx-ui/osc-brands-data';

const getBaseUrl = (slug?: string) =>
  slug ? new URL(slug, env('OHW_BASE_URL'))?.href : new URL(env('OHW_BASE_URL')).origin;

export type SearchUrlParameters = {
  languageCode: string;
  awc: string;
  cid: string;
  dclid: string;
  formData: FormData;
  gclid: string;
  isGroupSearch: boolean;
  baseAppUrl: string;
  url: string;
  wtmcid: string;
  brandCode: string;
};

export const GROUP_LIMIT = 10;
function generateGroupSearchUrl({
  formData,
  isGroupSearch,
  brandCode,
  languageCode,
}: SearchUrlParameters) {
  const { numRooms, numAttendees, dates, query } = formData;
  const { arrivalDate, departureDate } = dates;
  const queryVal = query;

  // if numAttendees isn't defined, make sure that we are requesting at least 10 rooms
  const numRoomsVal = Number(numAttendees)
    ? Number(numRooms)
    : Math.max(Number(numRooms), GROUP_LIMIT);

  const groupUrl = searchUriBuilder({
    brandCode: brandCode as keyof BrandCode,
    locale: languageCode,
    urlParams: {
      dates: {
        arrivalDate: arrivalDate ?? undefined,
        departureDate: departureDate ?? undefined,
      },
      placeId: formData.placeId,
      query: queryVal,
      numRooms: numRoomsVal,
      numAttendees: isGroupSearch ? Number(numAttendees) : 0,
      sessionToken: getVisitorId() ?? undefined,
    },
    baseUrl: getBaseUrl(),
    relative: false,
  });

  return groupUrl;
}
function generateNormalSearchUrl({ url, baseAppUrl }: SearchUrlParameters) {
  const origin = baseAppUrl ? new URL(baseAppUrl).origin : null;

  return origin ? `${origin}${url}` : url;
}

export function generateUrl(searchUrlParameters: SearchUrlParameters) {
  // For less than 10 rooms and no attendees, use transient search
  const isGroupSearchAndMoreThan10Rooms =
    searchUrlParameters.isGroupSearch && (searchUrlParameters?.formData?.numRooms || 10) >= 10;
  const hasAtLeastOneAttendee =
    searchUrlParameters?.formData?.numAttendees && searchUrlParameters?.formData?.numAttendees > 0;

  if (isGroupSearchAndMoreThan10Rooms || hasAtLeastOneAttendee) {
    return generateGroupSearchUrl(searchUrlParameters);
  }

  if (
    (!searchUrlParameters.formData?.dates?.arrivalDate &&
      !searchUrlParameters.formData?.dates?.departureDate) ||
    //dreamURIBuilder previously generates valid URI
    (searchUrlParameters.formData?.dates?.datesFlex &&
      searchUrlParameters?.url?.includes('/locations/'))
  ) {
    return new URL(searchUrlParameters.url, searchUrlParameters.baseAppUrl).toString();
  }
  return generateNormalSearchUrl(searchUrlParameters);
}
