import type { MouseEvent, ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect } from 'react';
import type { ConductricsSelection } from '@dx-ui/framework-conductrics';
import { sendReward, useConductricsSelection } from '@dx-ui/framework-conductrics';
import type { ExperimentationAgents } from './types';

const fallbackValue = {};
export const CpmConductricsAgentsContext = createContext<ExperimentationAgents>(fallbackValue);

export function CpmConductricsAgentsProvider({
  agentIds,
  children,
}: {
  agentIds: string[];
  children: ReactNode;
}) {
  if (!agentIds.length) {
    return (
      <CpmConductricsAgentsContext.Provider value={fallbackValue}>
        {children}
      </CpmConductricsAgentsContext.Provider>
    );
  }

  return <ConductricsFetcher agentIds={agentIds}>{children}</ConductricsFetcher>;
}

export function useCpmConductricsAgents() {
  const context = useContext(CpmConductricsAgentsContext);

  if (!context) {
    throw new Error(
      '`useCpmConductricsAgents` must be used within a `CpmConductricsAgentsProvider`'
    );
  }

  return context;
}

function ConductricsFetcher({ agentIds, children }: { agentIds: string[]; children: ReactNode }) {
  const isSingleAgent = agentIds.length === 1;
  const selections = useConductricsSelection(agentIds.map((agentId) => ({ agentId })));

  const handleClick = useCallback((event: MouseEvent<HTMLDivElement> | Event) => {
    if (!(event.target instanceof Element)) {
      return;
    }

    const interactiveElement = event.target.closest<HTMLButtonElement | HTMLAnchorElement>(
      'a, button'
    );

    if (!interactiveElement) {
      return;
    }

    const goal = interactiveElement.dataset.conductricsGoal;
    const value = interactiveElement.dataset.conductricsValue;

    if (goal) {
      sendReward(goal, castToNumber(value));
    }
  }, []);

  useEffect(() => {
    if (document?.body) {
      document.body.addEventListener('click', handleClick, { passive: true });
    }

    return () => {
      if (document?.body) {
        document.body.removeEventListener('click', handleClick);
      }
    };
  }, [handleClick]);

  // useConductricsSelection will return an object that is of type `ConductricsSelection` instead of
  // Record<AgentID, ConductricsSelection> when there is an array with a single agent ID passed to it.
  // We need to convert this to a record.
  const agents =
    isSingleAgent && isConductricsSingleSelection(selections)
      ? {
          [`${[agentIds[0]]}`]: selections,
        }
      : selections;

  return (
    <CpmConductricsAgentsContext.Provider value={agents}>
      {children}
    </CpmConductricsAgentsContext.Provider>
  );
}

function castToNumber(value?: string | number) {
  const v = Number(value);
  return Number.isFinite(v) ? v : undefined;
}

function isConductricsSingleSelection(value: unknown): value is ConductricsSelection {
  return (
    typeof value === 'object' &&
    value !== null &&
    'isLoaded' in value &&
    'error' in value &&
    'selection' in value
  );
}
