import cx from 'classnames';
import Image from 'next/image';

import { HeadingLevel } from '@dx-ui/osc-heading-level';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import { clearImageAnalyticsData } from '@dx-ui/config-metrics';

import type { OmittedImageProps, PropertyThemeOptions } from './collage';
import type { Variant } from '@dx-ui/osc-brand-buttons';

export type CollageContentProps = {
  brandComponentTheme?: CmsBrandComponentTheme;
  /**
   * To override default variant
   */
  brandLinkVariant?: Variant;
  /**
   * Text rendered in headline, approx 20 characters
   */
  headline?: string;
  /**
   * By default the collage is not animated
   */
  isAnimated?: boolean;
  isInView?: boolean;
  isDark?: boolean;
  /**
   * Controls the layout direction
   */
  isFlipped?: boolean;
  isLight?: boolean;
  /**
   * JSON that configures how the cta is rendered, label - approx 20 characters
   */
  link?: {
    url?: string;
    label?: string;
    adaDescription?: string;
    isNewWindow?: boolean;
    experimentationConfiguration?: CmsExperimentationConfiguration;
  };
  /**
   * Logo above text content
   */
  logo?: OmittedImageProps;
  /**
   * Text rendered in copy paragraph, approx 300 characters
   */
  longDescription?: string;
  /**
   * Text rendered in copy paragraph
   */
  shortDescription?: string;
  /**
   * Applies property theming
   */
  propertyTheme?: PropertyThemeOptions;
};

export const CollageContent = ({
  isFlipped,
  isDark,
  isLight,
  isInView,
  isAnimated,
  logo,
  headline,
  longDescription,
  brandComponentTheme,
  brandLinkVariant,
  shortDescription,
  link,
  propertyTheme,
}: CollageContentProps) => {
  return (
    <div
      data-testid="testBoxBgColor"
      className={cx(
        'flex flex-col p-4 md:p-8 lg:min-w-[400px] xl:py-24',
        isFlipped ? 'lg:pe-12 lg:ps-28 xl:pe-20 xl:ps-44' : 'lg:pe-28 lg:ps-12 xl:pe-32 xl:ps-24',
        {
          'bg-bg': !isDark && !isLight,
          'brand-ol:bg-primary': isDark,
          'brand-ol:bg-bg-light': isLight,
        },
        propertyTheme?.base
      )}
    >
      {logo ? (
        <div className="relative flex aspect-[3/2] max-h-40">
          <Image
            className={cx('object-contain', {
              'opacity-100': isInView && isAnimated,
              'duration-1000 ease-in-out transition-all motion-reduce:transition-none opacity-0 motion-reduce:opacity-100':
                isAnimated,
            })}
            data-testid="collageLogo"
            fill
            src={logo.imageUrl}
            alt={logo.altText}
          />
        </div>
      ) : null}

      {headline ? (
        <HeadingLevel
          headingLevelFallback={2}
          className={cx(
            'heading-2xl lg:heading-4xl start-10 my-2 motion-reduce:translate-y-2 lg:mb-2',
            {
              'delay-75 opacity-100 translate-y-2': isInView && isAnimated,
              'translate-y-4': !isInView && isAnimated,
              'duration-1000 ease-in-out transition-all motion-reduce:transition-none opacity-0 motion-reduce:opacity-100':
                isAnimated,
              '!text-text-inverse': isDark,
              'brand-ht:!text-text-inverse': isLight,
            }
          )}
        >
          {headline}
        </HeadingLevel>
      ) : null}

      {longDescription ? (
        <BrandTextBody
          className={cx('my-2 motion-reduce:translate-y-0', {
            'delay-200 translate-y-0 opacity-100': isInView && isAnimated,
            'translate-y-4': !isInView && isAnimated,
            'duration-1000 ease-in-out transition-all motion-reduce:transition-none opacity-0 motion-reduce:opacity-100':
              isAnimated,
            'text-text-inverse': isDark,
            'brand-ht:!text-text-inverse': isLight,
          })}
          brandComponentTheme={brandComponentTheme}
        >
          {longDescription}
        </BrandTextBody>
      ) : null}

      {shortDescription ? (
        <BrandTextBody
          className={cx('my-2 text-base motion-reduce:translate-y-0', {
            '!text-base': propertyTheme,
            'delay-300 translate-y-0 opacity-100': isInView && isAnimated,
            'translate-y-4': !isInView && isAnimated,
            'duration-1000 ease-in-out transition-all motion-reduce:transition-none opacity-0 motion-reduce:opacity-100':
              isAnimated,
            'text-text-inverse': isDark,
            'brand-ht:!text-text-inverse': isLight,
          })}
          brandComponentTheme={brandComponentTheme}
        >
          {shortDescription}
        </BrandTextBody>
      ) : null}

      {link?.label && link?.url ? (
        <div
          className={cx({
            'delay-500 translate-y-0 opacity-100': isInView && isAnimated,
            'translate-y-4': !isInView && isAnimated,
            'duration-1000 ease-in-out transition-all motion-reduce:transition-none opacity-0 motion-reduce:opacity-100':
              isAnimated,
          })}
        >
          <BrandLink
            data-testid="collageCtaButton"
            url={link.url}
            label={link.label}
            isNewWindow={link.isNewWindow}
            showNewWindowIcon={link.isNewWindow}
            brandComponentTheme={brandComponentTheme}
            data-conductrics-goal={link.experimentationConfiguration?.goal}
            data-conductrics-value={link.experimentationConfiguration?.value}
            variant={brandLinkVariant}
            onClick={() => clearImageAnalyticsData()}
          />
        </div>
      ) : null}
    </div>
  );
};
