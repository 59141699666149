import type { CpmMappedComponentData, CpmMappingFunctionInput } from '@dx-ui/cpm-sdk';
import type { HexVariant } from '@dx-ui/libs/osc-full-width-media';
import {
  FullWidthMedia,
  FullWidthMediaContentBlock,
  HamptonHexBlock,
  generatePartialFullWidthMediaMetrics,
} from '@dx-ui/libs/osc-full-width-media';

export function mapData({
  data,
}: CpmMappingFunctionInput<'Full-Width Media and Copy Overlay' | 'Full-Width Media and Copy'>) {
  const asset = data.cpmAssets[0];

  const captionData = asset
    ? {
        captionLink: asset.captionLink,
        caption: asset.caption,
      }
    : undefined;

  return {
    $ref: data.ref?.$ref,
    id: data.id,
    description: data.shortDescription,
    title: data.headline,
    link: data.link ?? undefined,
    desktopImageUrl: asset?.aspectRatios['16x9']?.url ?? '',
    mobileImageUrl: asset?.aspectRatios['1x1']?.url ?? '',
    captionData,
    imageAltText: asset?.altText ?? '',
    segmentIds: data.segmentIds,
    experimentationConfiguration: data.experimentationConfiguration,
    links: data.links,
  };
}

export function FullWidthMediaAndCopyOverlay({
  items = [],
  componentParams,
  mappedPage: { brandCode },
}: CpmMappedComponentData<
  'Full-Width Media and Copy Overlay' | 'Full-Width Media and Copy',
  ReturnType<typeof mapData>
>) {
  const data = items[0];

  if (!data) {
    return null;
  }

  const shouldDisplayStandardContent =
    !componentParams.display ||
    componentParams.display === 'mediaWithCopy' ||
    componentParams.display === 'static' ||
    componentParams.display === '';
  const shouldDisplayHamptonHex =
    [
      'hxHexagonBlue',
      'hxHexagonDarkBlue',
      'hxHexagonDarkOrange',
      'hxHexagonDarkPurple',
      'hxHexagonDarkRed',
      'hxHexagonRed',
    ].includes(componentParams.display) && brandCode === 'HP';

  return (
    <FullWidthMedia
      alignment={componentParams.textAlign}
      imageWrapperClass={shouldDisplayHamptonHex ? 'md:absolute md:size-full' : ''}
      image={{
        altText: data.imageAltText,
        mobileImageUrl: data.mobileImageUrl,
        desktopImageUrl: data.desktopImageUrl,
      }}
      captionData={data.captionData}
      metrics={generatePartialFullWidthMediaMetrics(data.title)}
    >
      {shouldDisplayStandardContent ? (
        <FullWidthMediaContentBlock
          link={data.link}
          headline={data.title}
          alignment={componentParams.textAlign}
          longDescription={data.description}
          brandComponentTheme={componentParams.theme}
        />
      ) : null}
      {shouldDisplayHamptonHex ? (
        <HamptonHexBlock
          link={data.link}
          variant={componentParams.display as HexVariant}
          headline={data.title}
        />
      ) : null}
    </FullWidthMedia>
  );
}
