import cx from 'classnames';
import { createCpmComponentDefinition, useCreateManageContentButton } from '@dx-ui/cpm-sdk';
import { DynamicGridWOM, DynamicGrid } from '@dx-ui/osc-dynamic-grids';
import { extractInstanceIndexFromId } from '../../utils';
import { AnimateRevealItem } from '@dx-ui/osc-animate-reveal-item';
import { HamptonHexVisual } from './HamptonHexVisual';
import { sendReward } from '@dx-ui/framework-conductrics';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';

export default createCpmComponentDefinition(
  '3-6-9 Grid',

  function mapData({ data, addIssue, clearIssue }) {
    const asset = data.cpmAssets[0];

    if (!asset) {
      addIssue({
        id: data.id,
        message: `"${data.name || data.displayName}" has no image - please attach one`,
      });
    } else {
      clearIssue(data.id);
    }

    const modalImageUrl = data.cpmAssets?.[0]?.aspectRatios['3x2']?.url;

    return {
      id: data.id ?? '',
      imageUrl: asset?.aspectRatios['3x2']?.url ?? '',
      imageAltTxt: asset?.altText ?? '',
      headline: data.headline,
      shortDescription: data.shortDescription,
      link: data.link ?? undefined,
      links: data.links,
      itemTitle: data?.label ?? '',
      captionData: asset?.caption
        ? {
            caption: asset?.caption ?? '',
          }
        : undefined,
      modalImageUrl,
    };
  },

  function GridThreeSixNine({
    items = [],
    listData,
    componentParams,
    mappedPage: { brandCode },
    metrics,
  }) {
    const createManageContentButton = useCreateManageContentButton();

    const { display } = componentParams;
    const isWom = display === 'wom';

    const isWA = brandCode === 'WA';

    if (!items.length || !listData) {
      return null;
    }

    const { id, headline, description, links, subheading } = listData;

    const itemsWithManageContentButton = items.map((item) => ({
      ...item,
      cmsDocumentControl: createManageContentButton(item.$ref),
    }));

    const onViewItem = (selectedItemId: string) => {
      const index = items.findIndex((item) => item.id === selectedItemId);
      const item = items[index];

      if (item?.experimentationConfiguration && item?.experimentationConfiguration.goal) {
        const val = Number(item?.experimentationConfiguration.value);
        sendReward(item?.experimentationConfiguration.goal, Number.isFinite(val) ? val : undefined);
      }

      metrics.trackGridItemClick?.([
        {
          itemNumber: index + 1,
          totalItems: items.length,
          gridNumber: extractInstanceIndexFromId(id),
          is4XGrid: false,
          label: isWom ? '' : item?.itemTitle ?? '',
        },
      ]);
    };

    const listLink = links[0];

    const isThemeAdded = Boolean(componentParams.theme);
    const hexEnabled =
      brandCode === 'HP' && componentParams.backgroundIllustration === 'hx-hexagon';

    if (display === 'wom') {
      return (
        <BrandComponentThemeInline
          componentClassName="threeSixNineWOMGrid"
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration,
          }}
        >
          <HamptonHexVisual theme={componentParams.theme ?? ''} enabled={hexEnabled}>
            <AnimateRevealItem delay={0} animationType="fade-in-up" isAnimated={brandCode === 'GU'}>
              <div
                data-testid="cpm-grid369-themed-wrapper"
                className={cx({
                  relative: hexEnabled,
                  'pt-10 pb-16': isThemeAdded,
                  'pt-6': !isThemeAdded,
                })}
              >
                <DynamicGridWOM
                  textAlign={componentParams.listTextAlign}
                  isAnimated={componentParams.animation}
                  listSubheading={subheading ?? ''}
                  imageAspectRatio="3:2"
                  listHeadline={headline ?? undefined}
                  listDescription={description ?? undefined}
                  id={id}
                  link={listLink}
                  items={itemsWithManageContentButton}
                  brandComponentTheme={componentParams.theme}
                />
              </div>
            </AnimateRevealItem>
          </HamptonHexVisual>
        </BrandComponentThemeInline>
      );
    } else {
      return (
        <BrandComponentThemeInline
          componentClassName="threeSixNineGrid"
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration,
          }}
        >
          <HamptonHexVisual theme={componentParams.theme ?? ''} enabled={hexEnabled}>
            <AnimateRevealItem delay={0} animationType="fade-in-up" isAnimated={brandCode === 'GU'}>
              <div
                data-testid="cpm-grid369-themed-wrapper"
                className={cx('relative', {
                  'pt-10 pb-16': isThemeAdded,
                  'pt-6': !isThemeAdded,
                })}
              >
                <DynamicGrid
                  textAlign={componentParams.listTextAlign}
                  listDescription={description ?? undefined}
                  listHeadline={headline ?? undefined}
                  id={id}
                  imageAspectRatio="3:2"
                  items={itemsWithManageContentButton}
                  onViewItem={onViewItem}
                  brandComponentTheme={componentParams.theme}
                  isAnimated={isWA}
                  variant="card"
                />
              </div>
            </AnimateRevealItem>
          </HamptonHexVisual>
        </BrandComponentThemeInline>
      );
    }
  }
);
