import { useRef } from 'react';
import cx from 'classnames';
import { useIntersectionObserver } from 'usehooks-ts';
import { useTranslation } from 'next-i18next';
import { ResponsiveImage, getCurrentAspectRatioAndUrl } from '@dx-ui/osc-responsive-image';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import type { CaptionProps } from '@dx-ui/osc-caption';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';

import { BrandLink } from '@dx-ui/osc-brand-buttons';
import type { Link as LinkType } from '@dx-ui/osc-link';
import Image from 'next/image';
import { useRect } from '@dx-ui/utilities-use-rect';

export const isReducedMotion =
  typeof window !== 'undefined' && typeof window.matchMedia === 'function'
    ? window.matchMedia('(prefers-reduced-motion: reduce)').matches
    : true;

export type HeroImageType = {
  /**
   * CPM ONLY: Dark opacity or Light opacity or none.
   */
  brandComponentTheme?: CmsBrandComponentTheme;
  /**
   * Caption link and text
   */
  captionData?: CaptionProps;
  aspectRatios?: {
    /**
     * Aspect Ratio of the Image at 1280px +
     */
    desktop: AspectRatio;
    /**
     * Aspect Ratio of the Image at 0px - 1280px
     */
    mobile: AspectRatio;
  };
  /**
   * Text content box alignment, center is default
   */
  alignContent?: CmsAlignContent;
  /**
   * Main headline of component
   */
  headline?: string;
  /**
   * Similar to a subheadline
   */
  shortDescription?: string;
  /**
   * Provides the long description
   */
  longDescription?: string | null;
  /**
   * OSC-Link
   */
  link?: (LinkType & { experimentationConfiguration?: CmsExperimentationConfiguration }) | null;
  /**
   * Allows animations to run when true, CMS option
   */
  isAnimated?: boolean;
  /**
   * Used to add or remove Horizontal Line between content, default 'false'
   */
  hasHorizontalLine?: boolean;
  /**
   * Small image icon in the upper left corner
   */
  badgeImageUrl?: string | null;
  /**
   * Small image icon alt text
   */
  badgeImageAltText: string;
  /**
   * Opens a gallery of additional images/information
   */
  hasGalleryButton?: boolean;
  /**
   * Alternative text of the Image (a11y)
   */
  imageAltText: string;
  /**
   * URL of the Image at 1280px +
   */
  image: {
    desktopUrl: string;
    mobileUrl: string;
  };
  /**
   * Space for a brand specific illustration overlay component
   */
  illustrationOverlay?: React.ReactNode;
  forTheStayIllustration?: React.ReactNode;
  captionMetricsOnClick?: () => void;
  brandLinkMetricsOnClick?: () => void;
};

/**
 * **Formerly Masthead with Image**
 *
 * The HeroImage is a full screen width image with optional added content, optional caption link, option badge icon, and optional gallery button.
 */
export const HeroImage: React.FC<HeroImageType> = ({
  brandComponentTheme,
  captionData,
  image,
  imageAltText,
  headline,
  shortDescription,
  longDescription,
  link,
  aspectRatios = { desktop: '18:5', mobile: '3:2' },
  alignContent = 'center',
  isAnimated = false,
  hasHorizontalLine = false,
  badgeImageAltText,
  badgeImageUrl,
  hasGalleryButton = false,
  illustrationOverlay,
  forTheStayIllustration,
  captionMetricsOnClick,
  brandLinkMetricsOnClick,
}) => {
  const { t } = useTranslation('osc-hero-image');
  const { ref: intersectionRef, isIntersecting: inView } = useIntersectionObserver({
    threshold: [0.15],
    freezeOnceVisible: true,
  });
  const attachedContentAnimate = !isReducedMotion && isAnimated ? intersectionRef : null;
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });

  if (!image) {
    return null;
  }

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  const isCenter = alignContent === 'center';
  const isLeft = alignContent === 'left';
  const isRight = alignContent === 'right';

  const { imageUrl, aspectRatio } = getCurrentAspectRatioAndUrl({
    width: rect?.width || 0,
    imageUrlMobile: image.mobileUrl,
    imageUrlTablet: image.mobileUrl,
    imageUrlDesktop: image.desktopUrl,
    aspectRatioMobile: aspectRatios.mobile,
    aspectRatioTablet: aspectRatios.mobile,
    aspectRatioDesktop: aspectRatios.desktop,
  });

  return (
    <div
      data-testid="heroImageContainer"
      className="relative block w-full overflow-hidden"
      ref={ref}
    >
      <ResponsiveImage
        aspectRatio={aspectRatio}
        imageUrl={imageUrl}
        altText={imageAltText}
        width={rect?.width ?? 0}
        captionData={captionData}
        onClick={captionMetricsOnClick}
      />

      <div className="flex">
        {badgeImageUrl ? (
          <div className="absolute top-12 ms-5 aspect-square h-24 sm:top-20 sm:h-44 xl:ms-10 xl:h-52">
            <Image className="object-contain" src={badgeImageUrl} alt={badgeImageAltText} fill />
          </div>
        ) : null}

        {hasGalleryButton ? (
          <button
            type="button"
            className={cx(
              'btn btn-sm btn-primary-outline bg-bg brand-qq:rounded-none brand-ol:rounded-none brand-wa:rounded-none brand-wa:font-normal brand-wa:uppercase absolute top-20 border-0 ltr:right-5 ltr:xl:right-10 rtl:left-5 rtl:xl:left-10',
              {
                'btn bg-bg-dark text-text-inverse': isDark,
              }
            )}
          >
            <div className="container px-0">
              {t('galleryButton')}
              <svg
                className={cx('fill-primary inline max-h-4 pl-1 pr-3 rtl:pl-3 rtl:pr-1', {
                  'fill-text-inverse': isDark,
                })}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m0 0v24h24v-24z" fill="none" />
                <rect height="9" rx="1.5" width="9" x="4.5" y="10.5" />
                <path d="m16 16.5c.28 0 .5-.22.5-.5v-6c0-1.38-1.12-2.5-2.5-2.5h-6c-.28 0-.5.22-.5.5s.22.5.5.5h6c.83 0 1.5.67 1.5 1.5v6c0 .28.22.5.5.5z" />
                <path d="m19 13.5c.28 0 .5-.22.5-.5v-6c0-1.38-1.12-2.5-2.5-2.5h-6c-.28 0-.5.22-.5.5s.22.5.5.5h6c.83 0 1.5.67 1.5 1.5v6c0 .28.22.5.5.5z" />
              </svg>
            </div>
          </button>
        ) : null}
      </div>

      {headline || shortDescription || longDescription || link ? (
        <div
          data-testid="testCssThemeVariant"
          ref={attachedContentAnimate}
          className={cx('brand-nd:bg-bg relative z-10 w-full pb-6 xl:pb-12', {
            'bg-bg-light brand-hi-refresh:bg-bg brand-gu:bg-quarternary brand-ou:bg-secondary':
              isLight,
            'bg-bg-dark brand-es:bg-bg brand-gu:bg-quarternary brand-hi-refresh:bg-bg brand-ou:bg-secondary brand-ey:bg-bg-light':
              isDark,
            'brand-ou:bg-bg-light': !isDark && !isLight,
          })}
        >
          <div
            data-testid="heroImageTextBoxContainerTest"
            className={cx('container flex flex-col', {
              'items-end': isRight,
              'items-start': isLeft,
              'items-center': isCenter,
            })}
          >
            <div
              className={cx(
                'brand-gu:!bg-opacity-100 image-corner-radius relative -mt-20 mb-0 w-full bg-[#f4f4f4] !bg-opacity-90 px-6 py-8 sm:px-12 xl:-mt-28 xl:px-16 xl:py-12',
                'brand-es:shadow-xl',
                'brand-gu:shadow-xl',
                'brand-hi-refresh:shadow-xl',
                'brand-nd:shadow-xl',
                'brand-wa:shadow-xl',
                {
                  'sm:max-w-xl 2xl:max-w-2xl': isRight || isLeft,
                  'brand-es:bg-bg brand-ey:bg-bg brand-gu:bg-bg-light brand-hi-refresh:bg-bg-light brand-lx:bg-bg-light brand-nd:bg-bg-light brand-ou:bg-bg-light':
                    isLight,
                  'brand-es:bg-bg-dark brand-ey:bg-bg-dark brand-gu:bg-bg-dark brand-hi-refresh:bg-bg-dark brand-nd:bg-bg-dark brand-ou:bg-bg-dark':
                    isDark,
                  'brand-es:bg-bg brand-ey:bg-bg-light brand-hi-refresh:bg-bg brand-nd:bg-bg brand-gu:bg-quarternary brand-ou:bg-secondary brand-lx:bg-bg':
                    !isDark && !isLight,
                }
              )}
            >
              {forTheStayIllustration ? forTheStayIllustration : null}

              {headline ? (
                <BrandTextHeader
                  className={cx(
                    'sm:heading-4xl heading-xl 2xl:heading-5xl whitespace-normal text-center delay-150 xl:text-center',
                    'brand-qq:heading-6xl brand-qq:2xl:heading-7xl',
                    'brand-wa:xl:heading-4xl',
                    {
                      'sm:text-left': isLeft || isRight,
                      'sm:text-center': isCenter,
                      'opacity-100 translate-y-0': inView && isAnimated,
                      'translate-y-4': !inView && isAnimated,
                      'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                        isAnimated,
                      'pb-3': hasHorizontalLine && !shortDescription,
                      'brand-es:text-text-inverse brand-ey:text-text-inverse brand-gu:text-text-inverse brand-hi-refresh:text-text-inverse brand-nd:text-text-inverse brand-ou:text-text-inverse':
                        isDark,
                    }
                  )}
                >
                  {headline}
                </BrandTextHeader>
              ) : null}

              {shortDescription ? (
                <BrandTextBody
                  className={cx(
                    '!text-primary pb-3 text-center font-sans !text-xl font-bold delay-300 xl:text-center',
                    'brand-qq:tracking-[.25em] brand-qq:uppercase brand-qq:text-primary brand-qq:!text-sm brand-qq:2xl:text-base',
                    {
                      'sm:text-left': isLeft || isRight,
                      'sm:text-center': isCenter,
                      'opacity-100 translate-y-0': inView && isAnimated,
                      'translate-y-4': !inView && isAnimated,
                      'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                        isAnimated,
                      'brand-es:!text-text-inverse brand-ey:!text-text-inverse brand-gu:!text-text-inverse brand-hi-refresh:!text-text-inverse brand-nd:!text-text-inverse brand-ou:!text-text-inverse':
                        isDark,
                    }
                  )}
                  brandComponentTheme={brandComponentTheme}
                >
                  {shortDescription}
                </BrandTextBody>
              ) : null}

              {hasHorizontalLine ? (
                <hr
                  className={cx(
                    'border-text-alt border-t pb-6',
                    'brand-qq:border-t-2 brand-qq:border-[#cccccc]',
                    {
                      'opacity-100 translate-y-2': inView && isAnimated,
                      'translate-y-4': !inView && isAnimated,
                      'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                        isAnimated,
                      'brand-ey:border-bg-light brand-gu:border-bg brand-hi-refresh:border-bg-light brand-nd:border-bg brand-ou:border-secondary':
                        isDark,
                      'brand-hi-refresh:border-primary': !isDark,
                    }
                  )}
                />
              ) : null}

              {longDescription ? (
                <BrandTextBody
                  className={cx(
                    'whitespace-normal pb-5 text-center text-sm font-normal tracking-normal sm:pb-6 xl:pb-8 xl:text-center',
                    'brand-qq:text-primary brand-qq:!text-base brand-qq:2xl:!text-xl',
                    {
                      'sm:text-left': isLeft || isRight,
                      'sm:text-center': isCenter,
                      'opacity-100 translate-y-0': inView && isAnimated,
                      'translate-y-4': !inView && isAnimated,
                      'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                        isAnimated,
                      'brand-es:text-text-inverse brand-es:[&>div>p>a]:text-text-inverse brand-es:[&>div>a]:text-text-inverse':
                        isDark,
                      'brand-ey:text-text-inverse brand-ey:[&>div>p>a]:text-text-inverse brand-ey:[&>div>a]:text-text-inverse':
                        isDark,
                      'brand-gu:text-text-inverse brand-gu:[&>div>p>a]:text-text-inverse brand-gu:[&>div>a]:text-text-inverse':
                        isDark,
                      'brand-hi-refresh:text-text-inverse brand-hi-refresh:[&>div>p>a]:text-text-inverse brand-hi-refresh:[&>div>a]:text-text-inverse':
                        isDark,
                      'brand-nd:text-text-inverse brand-nd:[&>div>p>a]:text-text-inverse brand-nd:[&>div>a]:text-text-inverse':
                        isDark,
                      'brand-ou:text-text-inverse brand-ou:[&>div>p>a]:text-text-inverse brand-ou:[&>div>a]:text-text-inverse':
                        isDark,
                    }
                  )}
                >
                  {longDescription}
                </BrandTextBody>
              ) : null}

              {link ? (
                <div
                  className={cx('text-center delay-700 xl:text-center', {
                    'sm:text-left': isLeft || isRight,
                    'sm:text-center': isCenter,
                    'opacity-100 translate-y-0': inView && isAnimated,
                    'translate-y-4': !inView && isAnimated,
                    'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                      isAnimated,
                  })}
                >
                  <BrandLink
                    label={link.label}
                    isNewWindow={link.isNewWindow}
                    showNewWindowIcon={link.isNewWindow}
                    url={link.url}
                    brandComponentTheme={brandComponentTheme}
                    data-conductrics-goal={link.experimentationConfiguration?.goal}
                    data-conductrics-value={link.experimentationConfiguration?.value}
                    anchorClassName={cx({
                      '!btn-primary brand-ht:border-none': isDark,
                      'brand-es:!bg-bg brand-es:!text-primary brand-es:hover:!bg-bg-dark brand-es:hover:!text-text-inverse brand-es:hover:!border-bg brand-es:focus:!bg-bg-dark brand-es:focus:!text-text-inverse brand-es:focus:!border-bg':
                        isDark,
                      'brand-ey:!bg-bg-light brand-ey:!text-primary brand-ey:hover:!bg-quarternary brand-ey:!border-none brand-ey:hover:!text-primary-alt brand-ey:focus:!bg-quarternary brand-ey:focus:!text-primary-alt':
                        isDark,
                      'brand-gu:!bg-transparent brand-gu:hover:!text-secondary brand-gu:hover:decoration-secondary':
                        isDark,
                      'brand-hi-refresh:!bg-bg brand-hi-refresh:!text-primary brand-hi-refresh:border-none brand-hi-refresh:hover:!bg-bg-light brand-hi-refresh:hover:!text-primary brand-hi-refresh:focus:!bg-bg-light brand-hi-refresh:focus:!text-primary':
                        isDark,
                      'brand-nd:!bg-bg brand-nd:!text-primary brand-nd:hover:!bg-primary-alt brand-nd:hover:!text-text-inverse brand-nd:focus:!bg-primary-alt brand-nd:focus:!text-text-inverse':
                        isDark,
                      'brand-ou:rounded-full brand-ou:!btn-quarternary brand-ou:!text-primary brand-ou:!border-primary brand-ou:hover:!text-quarternary brand-ou:hover:!border-quarternary':
                        isDark,
                      'brand-qq:!bg-transparent brand-qq:hover:!bg-transparent brand-qq:hover:!border-primary brand-qq:!btn-primary-outline brand-qq:!border-primary':
                        isDark,
                      'brand-wa:!text-primary brand-wa:tracking-[1px] brand-wa:font-normal brand-wa:uppercase brand-wa:underline brand-wa:underline-offset-8 brand-wa:decoration-2 brand-wa:!bg-transparent brand-wa:border-none brand-wa:px-0':
                        isDark,
                      'brand-ht:!btn-primary brand-ht:!text-text-inverse': isLight,
                    })}
                    metricsOnClick={brandLinkMetricsOnClick}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      {illustrationOverlay}
    </div>
  );
};

export default HeroImage;
