import type * as React from 'react';
import type { CpmNavigationList } from '@dx-ui/cpm-sdk';
import { createCpmComponentDefinition, shouldRenderExperiment } from '@dx-ui/cpm-sdk';
import { Footer as OscFooter } from '@dx-ui/osc-footer';
import type { Link as ComponentLink } from '@dx-ui/osc-link';
import { useBrandData } from '@dx-ui/cpm-mapping-shared';

import { defaultPhoneNumbers } from '../../utils/constants';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';

export default createCpmComponentDefinition(
  'Footer',

  ({ data }) => data,

  function Footer({
    componentParams,
    navigationList: listData,
    mappedPage,
    experimentationAgents,
  }) {
    const brandData = useBrandData();
    const { hidePageFooter: hideFooter, brandCode } = mappedPage;

    // Use `as` to narrow social media channels from `string` to specific channels ('Facebook' | 'Instagram' etc)
    const socialLinks = brandData?.socialMediaLinks as React.ComponentProps<
      typeof OscFooter
    >['socialLinks'];

    const contactPhoneLink = {
      url: `tel:${brandData?.phone?.number ?? defaultPhoneNumbers.number}`,
      label: brandData?.phone?.friendlyNumber ?? defaultPhoneNumbers.friendlyNumber,
    };

    const footerLinks: ComponentLink[] = [];

    if (isNavigationLinks(listData?.items)) {
      for (const { links } of listData?.items ?? []) {
        for (const link of links ?? []) {
          if (
            link?.url &&
            link.label &&
            shouldRenderExperiment(experimentationAgents, link.experimentationConfiguration)
          ) {
            footerLinks.push(link);
          }
        }
      }
    }

    if (hideFooter) {
      return null;
    }

    const theme = ['GU', 'ND'].includes(brandCode) ? 'dark' : undefined;

    return (
      <BrandComponentThemeInline componentParams={componentParams} brandCode={brandCode}>
        <OscFooter
          theme={theme}
          contactPhoneLink={contactPhoneLink}
          links={footerLinks}
          socialLinks={socialLinks}
        />
      </BrandComponentThemeInline>
    );
  }
);

function isNavigationLinks(items: unknown): items is CpmNavigationList['items'] {
  return Array.isArray(items) && items.length > 0 && 'links' in items[0];
}
