import { ResponsiveImage } from '@dx-ui/osc-responsive-image';
import { useRect } from '@dx-ui/utilities-use-rect';
import type { TResponsiveImage } from '@dx-ui/osc-responsive-image';
import { useRef } from 'react';
import type { CardCarouselProps } from '@dx-ui/osc-card-carousel';
import { CardCarousel } from '@dx-ui/osc-card-carousel';
import cx from 'classnames';
import { CustomMarkdown as Markdown } from '@dx-ui/osc-custom-markdown';
import { HeadingLevel } from '@dx-ui/osc-heading-level';

export type EditorialCarouselProps = {
  image: { url: string; alt: string; captionData?: TResponsiveImage['captionData'] };
  imgWrapperClassName?: string;
  /**
   * Can be Pullquote, Stats, Timeline or you can pass your own component
   */
  contentBelowImg: React.ReactNode;
  carousel: CardCarouselProps['items'];
  headline: string;
  subHeadline?: string;
  carouselHeadline?: string;
  brandComponentTheme?: CmsBrandComponentTheme;
};

/** The passed children will be placed to right of the image and above carousel. */
export const EditorialCarousel: React.FC<React.PropsWithChildren<EditorialCarouselProps>> = ({
  image,
  carousel,
  contentBelowImg,
  imgWrapperClassName,
  headline,
  carouselHeadline,
  subHeadline,
  brandComponentTheme,
  children,
}) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div className="grid grid-cols-1 py-24 md:grid-cols-[40%_60%] md:grid-rows-[auto_1fr_1fr]">
      <div className="order-none pb-10 pe-6 ps-6 md:order-2 md:pb-0 md:ps-11">
        <hgroup className="md:ps-[.8rem]">
          <HeadingLevel
            headingLevelFallback={2}
            className={cx('heading-4xl pt-6 md:pt-14', {
              '!text-text-inverse': isDark,
              'brand-ht:!text-text-inverse': isLight,
            })}
          >
            {headline}
            {subHeadline ? (
              <span
                className={cx(
                  'brand-es:heading-2xl brand-ey:heading-2xl brand-gu:heading-2xl brand-ou:heading-2xl block pt-1 font-serif text-2xl font-normal normal-case',
                  {
                    'brand-ey:!text-text-inverse brand-gu:!text-text-inverse brand-ou:!text-text-inverse':
                      isDark,
                  }
                )}
              >
                <Markdown>{subHeadline}</Markdown>
              </span>
            ) : null}
          </HeadingLevel>
        </hgroup>
        <div className="md:ps-[.8rem]">{children}</div>
      </div>
      <div ref={ref} className="order-none md:order-1 md:row-span-3">
        <ResponsiveImage
          aspectRatio="1:1"
          altText={image.alt}
          imageUrl={image.url}
          width={rect?.width ?? 0}
          wrapperClassName={imgWrapperClassName}
          captionData={image.captionData}
          className="image-corner-radius"
        />
        <div className="mt-20">{contentBelowImg}</div>
      </div>

      <div className="order-3 mt-14 sm:mt-20 md:row-span-2 lg:mt-24">
        <CardCarousel
          items={carousel}
          isEditorialComponent={true}
          mainHeadline={carouselHeadline}
          brandComponentTheme={brandComponentTheme}
        />
      </div>
    </div>
  );
};

export default EditorialCarousel;
