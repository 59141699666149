import { YouTubeVideo } from '@dx-ui/osc-youtube-video';
import { CpmMappingError, createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';

export default createCpmComponentDefinition(
  'Brand Video',

  function mapComponentData({ data }) {
    const video = data.videos?.[0];

    if (!video) {
      throw new CpmMappingError(
        `Brand Video requires a document has a video (${data.contentType} - ${data.displayName})`
      );
    }

    return {
      $ref: data.ref?.$ref,
      id: data.id ?? '',
      segmentIds: data.segmentIds,
      experimentationConfiguration: data.experimentationConfiguration,
      links: data.links,
      name: data.name ?? undefined,
      source: video?.source ?? '',
      title: video?.name ?? '',
      url: video?.url ?? '',
    };
  },

  function BrandVideo({ items = [], componentParams, mappedPage: { brandCode } }) {
    const data = items[0];

    if (!data) {
      return null;
    }

    return (
      <BrandComponentThemeInline
        componentClassName="video"
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <div className="container">
          <YouTubeVideo
            name={data.name}
            source={data.source}
            title={data.title}
            url={data.url}
            wrapperClassName="lg:max-h-[90vh]"
            id={`yt${data.id}`}
          />
        </div>
      </BrandComponentThemeInline>
    );
  }
);
