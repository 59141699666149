import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import type { StructuredAsset } from '@dx-ui/cpm-sdk';
import { Gallery, TabbedGallery } from '@dx-ui/osc-gallery';
import type { GalleryItems, GalleryItem } from '@dx-ui/osc-gallery';
import cx from 'classnames';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import { mapTextAlignToClassname } from '@dx-ui/osc-textual-block';
import { AnimateRevealItem } from '@dx-ui/osc-animate-reveal-item';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';

type GalleryImage = GalleryItem['image'] & Omit<GalleryItem, 'image' | 'category'>;
type GalleryImages = Array<GalleryImage>;

function transformImage(img: GalleryImage, category?: string) {
  return {
    id: img.id,
    ...(category && { category }),
    image: {
      alt: img.alt,
      aspectRatio: img.aspectRatio,
      url: img.url,
      captionData: img.captionData,
    },
  };
}

function mapAssetsToImages(assets: StructuredAsset[]): GalleryImages {
  return assets.map((asset) => ({
    id: asset?.id ?? crypto.randomUUID(),
    alt: asset?.altText ?? '',
    url: asset?.aspectRatios['3x2']?.url ?? '',
    aspectRatio: '3:2' as AspectRatio,
    captionData: asset?.caption
      ? {
          captionLink: asset.captionLink ?? undefined,
          caption: asset.caption ?? '',
        }
      : undefined,
  }));
}

export default createCpmComponentDefinition(
  'Gallery',

  function mapComponentData({ data }) {
    return {
      $ref: data?.ref?.$ref,
      id: data?.id ?? '',
      images: mapAssetsToImages(
        data?.cpmAssets?.filter((asset): asset is StructuredAsset => asset !== undefined) ?? []
      ),
      category: data.label || data?.headline || '',
      segmentIds: data.segmentIds,
      experimentationConfiguration: data.experimentationConfiguration,
      links: data.links,
    };
  },

  function CpmGallery({ items = [], listData, componentParams, mappedPage: { brandCode } }) {
    if (!items.length) {
      return null;
    }

    const isTabbedGallery = items.length > 1;

    const galleryItems: GalleryItems = isTabbedGallery
      ? items.flatMap((item) => item?.images?.map((img) => transformImage(img, item?.category)))
      : items[0]?.images?.map((img) => transformImage(img)) || [];

    const isGraduate = brandCode === 'GU';

    const headline = listData?.headline || undefined;
    const subheading = listData?.subheading || undefined;
    const link = listData?.links?.[0] || undefined;

    const brandComponentTheme = componentParams.theme;
    const isDark = brandComponentTheme === 'dark';
    const isLight = brandComponentTheme === 'light';
    const textAlign = componentParams.textAlign || 'left';

    const isCTADisplayed = isTabbedGallery ? true : galleryItems.length < 13;

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration || 'none',
        }}
      >
        <AnimateRevealItem delay={0} animationType="fade-in-up" isAnimated={isGraduate}>
          <div className="container">
            <div className={cx({ 'mb-8': headline || subheading })}>
              {headline ? (
                <BrandTextHeader
                  className={cx(mapTextAlignToClassname(textAlign), {
                    '!text-text-inverse': isDark,
                    'brand-ht:text-text-inverse': isLight,
                  })}
                >
                  {headline}
                </BrandTextHeader>
              ) : null}

              {subheading ? (
                <BrandTextBody
                  className={cx(mapTextAlignToClassname(textAlign), {
                    'text-text-inverse': isDark,
                    'brand-ht:text-text-inverse': isLight,
                  })}
                  brandComponentTheme={brandComponentTheme}
                >
                  {subheading}
                </BrandTextBody>
              ) : null}
            </div>

            {isTabbedGallery ? (
              <TabbedGallery
                items={galleryItems}
                isAnimated={isGraduate}
                brandComponentTheme={brandComponentTheme}
              />
            ) : (
              <Gallery
                items={galleryItems}
                isAnimated={isGraduate}
                brandComponentTheme={brandComponentTheme}
              />
            )}

            {link && isCTADisplayed ? (
              <div className="mt-8 flex justify-center">
                <BrandLink
                  isNewWindow={link.isNewWindow}
                  showNewWindowIcon={link.isNewWindow}
                  url={link.url}
                  label={link.label}
                  brandComponentTheme={brandComponentTheme}
                />
              </div>
            ) : null}
          </div>
        </AnimateRevealItem>
      </BrandComponentThemeInline>
    );
  }
);
