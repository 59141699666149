import type { OscDomLink } from '@dx-ui/cpm-sdk';
import { createCpmComponentDefinition, removeNullyFromArray } from '@dx-ui/cpm-sdk';
import crypto from 'crypto';
import Accordion, { type AccordionDetails } from './Accordion';
import { Content, ContentWrapper, type ContentData } from './AccordionContent';
import type { ContentBlock } from 'dx-shared-schema/dist/types/couchbase/story';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';

interface FaqItem {
  question?: string;
  answerParagraph?: string[];
  answerOrderedList?: string[];
  answerUnOrderedList?: string[];
  links?: OscDomLink[];
}

interface FaqBlock {
  faq?: FaqItem[];
}

type AccordionDetailsData = Omit<AccordionDetails, 'content'> & {
  content: ContentData[];
};

function hash<T>(x: T): string {
  return crypto.createHash('md5').update(JSON.stringify(x)).digest('hex');
}

function remapFaqContentToDetails(faqContent: FaqBlock[]): AccordionDetailsData[] {
  const faqBlocks = removeNullyFromArray(faqContent.flatMap((x) => x.faq));

  return faqBlocks.map((faqItem): AccordionDetailsData => {
    const content: ContentData[] = [];

    if (Array.isArray(faqItem.answerParagraph) && faqItem.answerParagraph.length > 0) {
      faqItem.answerParagraph.forEach((paragraph) => {
        content.push({
          description: paragraph,
          key: hash(paragraph),
        });
      });
    }

    if (Array.isArray(faqItem.answerOrderedList) && faqItem.answerOrderedList.length > 0) {
      content.push({
        orderedList: faqItem.answerOrderedList,
        key: hash(faqItem.answerOrderedList),
      });
    }

    if (Array.isArray(faqItem.answerUnOrderedList) && faqItem.answerUnOrderedList.length > 0) {
      content.push({
        unorderedList: faqItem.answerUnOrderedList,
        key: hash(faqItem.answerUnOrderedList),
      });
    }

    if (Array.isArray(faqItem.links) && faqItem.links.length > 0) {
      content.push({
        links: faqItem.links,
        key: hash(faqItem.links),
      });
    }

    return {
      key: hash(faqItem),
      collapsedButtonLabel: faqItem.question ?? '',
      expandedButtonLabel: faqItem.question ?? '',
      content,
    };
  });
}

function keyContent(blockContent: ContentBlock[]): ContentData[] {
  return blockContent.map((block) => ({
    ...block,
    key: hash(block),
  }));
}

export default createCpmComponentDefinition(
  'Accordion',

  function mapComponentData({ data }) {
    return {
      key: `acc${data.id}`,
      links: data.links,
      collapsedButtonLabel: data.headline || data.label || data.title || '',
      expandedButtonLabel: data.headline || data.label || data.title || '',
      content: keyContent(data.contentBlock ?? []),
      faqContent:
        data.contentType === 'dxcms:Faq' ? remapFaqContentToDetails(data.subTopic ?? []) : null,
    };
  },

  function CpmAccordion({
    items = [],
    listData,
    componentParams,
    mappedPage: { brandCode },
    experimentationAgents,
  }) {
    const firstItem = items[0];

    if (!firstItem) {
      return null;
    }

    const renderedItems: AccordionDetailsData[] =
      (items[0]?.faqContent ? firstItem.faqContent : items) ?? [];

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration || 'none',
        }}
      >
        <Accordion
          headline={listData?.headline ?? undefined}
          description={listData?.description ?? undefined}
          brandComponentTheme={componentParams.theme}
          details={renderedItems.map(({ content, ...data }) => ({
            ...data,
            content: (
              <ContentWrapper theme={componentParams.theme}>
                {content.map(
                  (content) => (
                    <Content
                      key={content.key}
                      content={content}
                      experimentationAgents={experimentationAgents}
                    />
                  )
                  // null
                )}
              </ContentWrapper>
            ),
          }))}
        />
      </BrandComponentThemeInline>
    );
  }
);
