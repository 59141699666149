import {
  CpmMappingError,
  createCpmComponentDefinition,
  useCreateManageContentButton,
} from '@dx-ui/cpm-sdk';
import { useState } from 'react';
import type { HeadingLevel } from '@dx-ui/osc-heading-level';
import { HeadingLevelProvider } from '@dx-ui/osc-heading-level';
import { BrandCarousel } from './BrandCarousel';
import { extractInstanceIndexFromId } from '../../utils';

import { BrandCarouselEditorButtons } from './BrandCarouselEditorButtons';
import { AnimateRevealItem } from '@dx-ui/osc-animate-reveal-item';
import { ForTheStayOverlay } from './ForTheStayOverlay';
import { BrandComponentThemeInline } from '@dx-ui/osc-brands-wrappers';

export default createCpmComponentDefinition(
  'Carousel',

  function mapData({ data, index, componentParams }) {
    const asset = data.cpmAssets[0];

    if (!asset) {
      throw new CpmMappingError(`${data.displayName || data.name} is missing an image`);
    }

    return {
      $ref: data.ref?.$ref,
      id: data.id ?? '',
      image: {
        alt: asset.altText,
        url: asset.aspectRatios['3x2']?.url ?? '',
        captionData: asset.caption
          ? {
              captionLink: asset.captionLink ?? undefined,
              caption: asset.caption ?? '',
            }
          : undefined,
      },
      headline: data.headline,
      description: data.longDescription,
      link: data.link?.url ? data.link : null,
      links: data.links,
      topHeadingLevel: calculateHeadingLevel(index, componentParams.topHeadingLevel),
    };
  },

  function BrandCarouselCpm({
    items = [],
    listData,
    componentParams,
    metrics,
    mappedPage: { brandCode },
    isCPMEditor,
  }) {
    const [slideIndex, setSlideIndex] = useState(0);
    const { textAlign, noMargin } = componentParams;

    const createManageContentButton = useCreateManageContentButton();

    const id = listData?.id ?? '';

    const itemsWithManageContentButton = items.map((item) => ({
      ...item,
      cmsDocumentControl: createManageContentButton(item.$ref),
    }));

    const onArrowClick = (newIndex: number) => {
      setSlideIndex(newIndex);

      if (metrics.trackCarouselNavigationClick) {
        metrics.trackCarouselNavigationClick([
          {
            navigationClick: true,
            carouselNumber: extractInstanceIndexFromId(id),
            index: newIndex + 1,
            totalDots: items.length,
          },
        ]);
      }
    };

    const imageCount = items.length;
    const images = items.map((item, index) => ({
      ...item.image,
      children:
        index === 0 && componentParams.text === 'forTheStay' ? (
          <ForTheStayOverlay
            overlayPosition={componentParams.orientation ?? 'left'}
            count={imageCount}
          />
        ) : null,
    }));

    return (
      <HeadingLevelProvider level={itemsWithManageContentButton[slideIndex]?.topHeadingLevel}>
        <BrandComponentThemeInline
          brandCode={brandCode}
          componentClassName="brandCarousel"
          componentParams={componentParams}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration,
          }}
        >
          <AnimateRevealItem delay={0} animationType="fade-in-up" isAnimated={brandCode === 'GU'}>
            <BrandCarousel
              items={itemsWithManageContentButton}
              isFullScreen={noMargin}
              images={images}
              onArrowClick={onArrowClick}
              alignContent={textAlign}
              brandComponentTheme={componentParams.theme}
            />
          </AnimateRevealItem>
          <BrandCarouselEditorButtons
            isCPMEditor={isCPMEditor}
            items={itemsWithManageContentButton}
            slideIndex={slideIndex}
          />
        </BrandComponentThemeInline>
      </HeadingLevelProvider>
    );
  }
);

export function calculateHeadingLevel(index: number, headingLevel?: number) {
  let level;

  if (headingLevel === 1) {
    if (index === 0) {
      level = 1;
    } else {
      level = 2;
    }
  } else {
    level = headingLevel;
  }

  return level as HeadingLevel;
}
